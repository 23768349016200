
import { Options, Vue } from "vue-class-component";
import Icons from "@/app/ui/components/lp-icon/index.vue";

@Options({
  props: { 
    name: { 
      default: "", 
      type: String 
    },
    spesific: { 
      default: false, 
      type: Boolean 
    },
    isSpesificActive: { 
      default: false, 
      type: Boolean 
    },
    isSpesificHover: { 
      default: false, 
      type: Boolean 
    },
  },
  components: { Icons },
})
export default class SidebarIcon extends Vue {}
